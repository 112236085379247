/********************************************
 L4M (WZ)
********************************************/
body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,main,menu,nav,output,ruby,section,summary,time,mark,audio,video
{
	margin:0;
	padding:0;
	border:0;
	font:inherit;
	vertical-align:baseline;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

html {
	font-size: .625em; /* fallback IE8+ */
	font-size: calc(1em * .625); /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
	font-family:Arial, verdana;
	color:#323232;
}
body {
  font-size: 1.4em; /* base font-size is equivalent "14px" */
  padding-top: 9rem;
  background-color: #f2f2f2;
}

a {
	text-decoration: none;
	color: #0099cb;
}

i {
	font-style: italic;
}

.global-wrapper {
	text-align:center;
	padding: 0;
	margin: 0;
}

.nav-wrapper {
	position: fixed;
	top: 0;
	left:0;
	right: 0;
	background-color: #282828;
}

nav, .container, footer {
	width: 100%;
	max-width: 600px;
	margin: auto;
}

.body-admin .container {
	max-width: 1170px;
}

nav {
	display: inline-block;
	vertical-align: middle;
	height: 8.4rem;
	line-height: 4.2rem;
}

nav a {
	display: inline-block;
	margin: auto 1em;
	vertical-align: middle;
	text-transform: uppercase;
	color: #fff;
}
	
.container {
	padding: 2em;
	background-color: #fff;
	text-align: left;
}

h2 {
	font-size: 1.75rem
}

p, form>div {
	margin: 1em auto;
}

label {
	display:block;
	width: 100%;
	margin-bottom: .5em
}

input {
	width: 100%;
	display:block;
	border: 1px solid #ccc;
	padding: .5em;
	border-radius: .5rem;
	
}

.main-action {
	cursor: pointer;
	display: block;
	padding: 1em 2em;
    background-color: #0099cb;
    color: #fff;
	border: 0;
	margin: 2em auto;
	width: 80%;
	max-width: 400px;
	text-transform: uppercase;
	text-align: center;
	
}

form ul {
	list-style-type: none;
}

.message-notice {
	font-weight: bold;
}

.form-error-message, .message-error {
	color: #b94a48;
}

footer {
	margin-top: 3rem;
	background-color: #282828;
	color: #fff;
	padding: 2rem;
}

@media screen and (min-width: 640px) {
	nav {
		height: 4.2rem;
	}
	
	body {
		padding-top: 6rem;
	}
}